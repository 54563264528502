import { BaseIP, BaseURL, BaseURLMaster } from "./APIURL";

export const getCardData = BaseURL + "dashboard/getCardData";


// /institute/sss/dashboard/

// GetMapping    /getCardData
// @RequestParam("acyr") long acYear, @RequestParam("sid") long sectionId, @RequestParam("bid") long branchId

export const getStudentSummery = BaseURL + "dashboard/getStudentSummery";
///institute/sss/dashboard/getStudentSummery



export const getCardDataForSanstha = BaseURL + "sanstha/dashboard/getCardDataForSanstha";
// /institute/sss/sanstha/dashboard/

//  GetMapping  - /getCardDataForSanstha



export const getStudentSummeryForSanstha = BaseURL + "sanstha/dashboard/getStudentSummeryForSanstha";

//  GetMapping  /getStudentSummeryForSanstha
// @RequestParam("acyr") long acYear


// Student Birthday list

// localhost:8081/institute-sss/institute/sss/dashboard/getTodayStudentBirthDay?acyr=2023&sid=3&bid=1

// resp : [
//     {
//         "classEn": "10th",
//         "divMr": "क ",
//         "regNo": 12645,
//         "classMr": "10 वी ",
//         "divEn": "C",
//         "studentNameMr": "चौगुले रोहन महेश",
//         "studentNameEn": "  "
//     }]
export const getStudentsBdayListAPIURL = BaseURL + "dashboard/getTodayStudentBirthDay";

// Employee Birthday List

// localhost: 8081/institute-sss/institute/sss/dashboard/getTodayEmployeeBirthDay?sid=3&bid=1

// resp: [
//     {
//         "empId": 353,
//         "empNameMr": "कदम प्रकाश विश्वनाथ",
//         "empNameEn": "Kadam Prakash Vishvanath"
//     }
// ]
export const getEmployeeBdayListAPIURL = BaseURL + "dashboard/getTodayEmployeeBirthDay";

// Student Category Summary

// localhost: 8081 / institute - sss / institute / sss / dashboard / getStudentCategorySummary ? acyr = 2023 & sid=3 & bid=1

// resp: [
//     {
//         "classEn": "5th ",
//         "divMr": "अ ",
//         "classMr": "5 वी ",
//         "divEn": "A",
//         "boysCount": 3,
//         "girlsCount": 1,
//         "catMinEn": "SC",
//         "catMinMr": "अनुसूचीत जाती"
//     }]
export const getStudCategorySummaryAPIURL = BaseURL + "dashboard/getStudentCategorySummary";

// Student Minority Summary

// localhost: 8081 / institute - sss / institute / sss / dashboard / getStudentMinoritySummary ? acyr = 2023 & sid=3 & bid=1

// resp: [
//     {
//         "classEn": "5th ",
//         "divMr": "अ ",
//         "classMr": "5 वी ",
//         "divEn": "A",
//         "boysCount": 26,
//         "girlsCount": 23,
//         "catMinEn": "Majority",
//         "catMinMr": "बहुसंख्य"
//     }]
export const getStudMinoritySummaryAPIURL = BaseURL + "dashboard/getStudentMinoritySummary";


//Hostel Dashboard
//http://192.168.29.102:8081/institute-sss/institute/sss/dashboard/hostel/getHostelCardData?year=2023&bid=36
export const getHostelDetailsAPIURL = BaseURL + "dashboard/hostel/getHostelCardData";

//Sanstha Dashboard
//http://192.168.29.102:8081/institute-sss/institute/sss/sanstha/dashboard/getStudentCategoryCountSanstha?acyr=2023
export const getStudentCategorySummarySansthaAPIURL = BaseURL + "sanstha/dashboard/getStudentCategoryCountSanstha";

//http://192.168.29.102:8081/institute-sss/institute/sss/sanstha/dashboard/getStudentMinorityCountSanstha?acyr=2023
export const getStudentMinoritySummarySansthaAPIURL = BaseURL + "sanstha/dashboard/getStudentMinorityCountSanstha";

//http://192.168.195.102:8081/institute-sss/institute/sss/dashboard/getEmployeeAttendanceSummary?date=2024-08-28
export const getSchoolWiseEmployeeAttendaceAPIURL = BaseURL + "dashboard/getEmployeeAttendanceSummary";


